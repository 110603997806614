import React from "react";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo, slogan, text }) {

    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:mb-10 lg:mb-20">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover after:bg-[#333]"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-[90%] mx-auto absolute bottom-[100px] md:bottom-[50px] lg:bottom-[70px] md:text-start text-center text-white">
                    <div className="w-full md:w-1/2 text-center md:text-start p-4">
                        <h1 className="text-[45px] md:text-[3em] lg:text-[4em]">{slogan}</h1>
                        <p className="px-5 md:px-[0%]">{text}</p>
                        <ButtonContent btnStyle="three" />
                    </div>

                    <div className="md:w-1/2 w-full h-auto flex justify-center items-center">
                        <img
                            src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/A%C3%B1os%20de%20experiencia%2F20%20A%C3%B1os.png?alt=media&token=3662cdb5-cad0-4ecc-b054-26a238764510"
                            alt="photo_hero"
                            className="md:w-[50%] w-[50%] h-auto"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;